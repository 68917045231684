export default function Landing_1() {
  return (
    <section className='relative bg-black h-screen bg-[url("/bg_landing_text.png")] bg-center bg-no-repeat bg-[length:220%] md:bg-[length:100%] overflow-hidden'>
      <div className='relative inset-0 aspect-[2048/1585] top-1/2 -translate-y-1/2'>
        <a
          className='absolute top-[55%] h-[16%] md:top-[52%] left-0 w-full md:h-[8.2%] z-10 md:w-[44%] md:left-[27.86%] text-transparent'
          href='https://buff-100.com'
          target="_blank"
        >
          buff-100.com
        </a>
      </div>
    </section>
  )
}
